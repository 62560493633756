<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title> Maksusuoritukset laskulle {{ invoice.invoiceNumber }} </v-card-title>

      <v-card-text class="card-comments">
        <div v-for="(item, idx) in invoice.paidAmounts.slice().reverse()" :key="idx + 'b'">
          <div class="mb-1">
            <div class="paid-amounts" style="font-size: 14px; margin-bottom: 1px">
              <p>Kirjauspäivä:</p>
              <p>
                <strong>{{ formatDate(item.date) }}</strong>
              </p>
            </div>
            <div class="paid-amounts" style="margin-bottom: -1px; font-size: 14px">
              <p>Summa:</p>
              <p>
                <strong>{{ formatCurrency(item.amount) }}</strong>
              </p>
            </div>

            <v-divider v-if="idx < invoice.paidAmounts.length - 1" class="mt-2 mb-2"></v-divider>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
export default {
  props: {
    invoice: {
      type: Object,
      default() {
        return {};
      },
    },
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
