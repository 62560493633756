<template>
  <v-dialog v-model="dialog" width="650">
    <v-card>
      <v-card-title>
        {{ invoice.title }}
      </v-card-title>

      <v-card-text class="card-comments">
        <div v-for="(comment, idx) in invoice.comments.slice().reverse()" :key="idx + 'a'">
          <div class="mb-1">
            <small style="font-size: 11px"
              ><strong>{{ formatDate(comment.date) }}</strong></small
            >

            <div style="margin-top: 5px">
              <p
                v-if="comment.statusCode && String(comment.statusCode) in ropo24Statuses"
                style="margin-bottom: 5px; font-size: 13px"
              >
                <strong>Tila:</strong>
                {{ ropo24Statuses[String(comment.statusCode)] }}
              </p>

              <p style="margin-bottom: 2px; font-size: 13px">
                <strong> {{ comment.title }}</strong>
              </p>

              <p v-if="comment.amount" style="margin-bottom: 0px; font-size: 13px">
                Suoritus: {{ formatCurrency(comment.amount) }}
              </p>

              <p
                v-if="isNaN(comment.text)"
                style="margin-bottom: -4px; font-size: 13px; white-space: pre-line"
              >
                {{ comment.text }}
              </p>

              <v-divider v-if="idx < invoice.comments.length - 1" class="mt-2 mb-2"></v-divider>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
import ropo24Statuses from "@/configs/ropo24Statuses.json";
export default {
  props: {
    invoice: {
      type: Object,
      default() {
        return {};
      },
    },
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      ropo24Statuses: JSON.parse(JSON.stringify(ropo24Statuses)),
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
